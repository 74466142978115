/**  =====================
      Form Componant css start
==========================  **/

.custom-select,
.form-control {
  background: $theme-background;
  padding: 10px 20px;
  font-size: 14px;
  height: auto;

  &.form-control-lg {
    font-size: 1.25rem;
  }

  &.form-control-sm {
    height: calc(1.8125rem + 2px);
    font-size: 0.875rem;
  }

  &.form-control-range {
    box-shadow: none;
  }

  &:focus {
    background: $theme-background;
  }

  &::-moz-placeholder {
    color: #aaaeb3;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: #aaaeb3;
  }

  &::-webkit-input-placeholder {
    color: #aaaeb3;
  }
}
/* input group start */

.input-group {
  background: $theme-background;

  .input-group-text {
    padding-left: 15px;
    padding-right: 15px;
    background: transparent;
    i {
      font-size: 20px;
    }
  }

  .custom-file-label {
    margin-bottom: 0;
  }

  .btn {
    margin-bottom: 0;
    margin-right: 0;
  }
}

.cust-file-button {
  .custom-file-label {
    height: calc(2.25rem + 8px);
    line-height: 2.5;

    &::after {
      padding: 0.775rem 0.75rem;
      height: 2.65rem;
    }
  }
}

.custom-select {
  height: calc(2.25rem + 9px);
}
/* input group End */
/**====== Form Componant css end ======**/
select.form-control:not([size]):not([multiple]) {
  height: calc(2.55rem + 2px);
}
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]),
.input-group-lg
  > .input-group-append
  > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg
  > .input-group-prepend
  > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
  height: calc(3.375rem + 2px);
}

.minicolors {
  .form-control {
    padding: 6px 12px 5px 44px;
  }
}
/* form-select */

.select2-container {
  width: 100% !important;
}
.tt-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  cursor: pointer;
}
.bootstrap-tagsinput {
  width: 100%;
}
.custom-file-label {
  height: auto;
  &:after {
    height: auto;
    padding: 0.55rem 0.75rem;
  }
}
.form-control.is-invalid,
.was-validated .form-control:invalid,
.was-validated .form-control:valid,
.form-control.is-valid {
  background-position: calc(100% - 10px) calc(0.375em + 0.4875rem);
}

.was-validated .form-control:invalid ~ .invalid-feedback {
  background: #fff;
  margin-top: 0;
  padding-top: 0.25em;
}

.campaignDetailsTable {
  width: 100%;

  tbody tr:nth-of-type(2n + 1) {
    background-color: transparentize($primary-color, 0.95);
  }

  td:first-of-type {
    background-color: rgba(45, 51, 147, 0.05);
  }

  td:last-of-type {
    padding: 7px;
  }
}

.campaignListTable {
  .table-responsive {
    overflow: visible !important;

    .dropdown {
      button {
        border: none;
        background: none;
        box-shadow: none;
      }

      svg {
        color: #000;
      }
    }

    .dropdown-toggle {
      &::after {
        display: none;
      }

      &:focus {
        box-shadow: none;
      }

      &:active {
        background: none !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
  }

  .row {
    margin-right: -15px;
  }
}

@media screen and (max-width: 922px) {
  .campaignListTable {
    .table-responsive {
      overflow-x: auto !important;
    }
  }
}
.UserListTable {
  .m-l-20 {
    margin-left: 0px;
  }
}
.profileContainer {
  .form-control-file,
  .form-control-range {
    padding-bottom: 10px;
  }

  .form-inline {
    label {
      margin-left: 5px;
    }
  }
}
.campaignCreatePage {
  .form-control-file,
  .form-control-range {
    padding-bottom: 10px;
  }
}
#deleteButton {
  position: absolute;
  left: 290px;
  margin-top: 10px;
}

.answer-div {
  border-left: 3px solid #d1dbd1;
  padding: 10px;
}

.notification-media {
  border-radius: 20px;
  border-style: groove;
}
#swal2-content {
  text-align: left;
  font-size: 14px;
  td:last-of-type {
    padding: 10px;
    padding-left: 40px;
  }
}
#controlFormId {
  width: 80%;
  .form-control {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
#downloadIcon {
  height: 65%;
  width: 100%;
}
#login-click {
  text-decoration: underline;
}
#reset-click {
  text-decoration: underline;
}
#login-mailclick {
  text-decoration: underline;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.popupSpan {
  display: none;
}
.campaignSpan:hover {
  cursor: pointer;
  position: relative;
}
.campaignSpan:hover div {
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 5px 20px 5px 5px;
  display: inline-block;
  z-index: 100;
  background: #ffffff;
  left: 60px;
  margin: 15px;
  width: 350px;
  word-wrap: break-word;
  position: absolute;
  top: -45px;
  text-decoration: none;
  flex-direction: column;
  font-weight: bold;
  white-space: pre-line;
}
.discSpan {
  padding-right: 10px;
}
.contentSpan {
  display: flex;
}
.table-responsive {
  overflow-x: auto;
}
.assetName-class {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table td .d-flex {
  width: 80%;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.table thead th {
  vertical-align: middle !important;
}

#notificationBody:hover {
  cursor: pointer;
  position: relative;
}
.notificationPopup {
  display: none;
}
#notificationBody:hover div {
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 5px 20px 5px 5px;
  display: flex;
  z-index: 100;
  background: #ffffff;
  left: 15px;
  margin: 15px;
  width: 424px;
  position: absolute;
  top: -54px;
  text-decoration: none;
  flex-direction: column;
  font-weight: bold;
  white-space: pre-line;
  overflow: auto;
}
.notificationSpan {
  display: block;
}

.arrowIcon:hover {
  color: blue;
  opacity: 0.5;
  transition: 2ms;
}

.faq-div {
  background-color: #e9ecec;
  padding: 10px;
  border-radius: 7px;
  color: #3e2929;
  font-weight: 1000;
  font-size: 17px;
}

.new-campaign-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clone-campaign-list {
  max-height: 400px;
  overflow-y: scroll;
  background-color: #f1ebeb38;
}

.campaign-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.asset-detail-container {
  width: 80%;
  margin: auto;
  background-color: #f4f7fa;
  padding: 30px;
  border: 4px solid #efe7e7;
  min-width: 550px;
  max-height: 450px;
  overflow-y: scroll;
}

.detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px;
  padding: 3px;
}

.detail-key {
  width: 50%;
  margin: auto;
  font-size: 14px;
}

.detail-value {
  width: 50%;
  margin: auto;
  position: relative;
  padding: 2px;
  line-height: 2;
  font-size: 14px;
}

.copy-text {
  position: absolute;
  right: 0;
}

.copy-text :hover {
  color: black;
}

.detail-value:hover {
  font-weight: bold;
  cursor: pointer;
}
.download-report-btn {
  align-items: center;
  display: inherit;
  border-radius: 5px;

  background-color: #2d3393;
  padding: 8px;
  cursor: pointer;
  color: #fff;
}

.download-report-btn:hover {
  background-color: #060c69;
}

.goback-div {
  margin-top: -10px;
  margin-bottom: 20px;
  font-size: medium;
  text-align: center;

  span {
    font-size: medium;
    background-color: #e5e5e5;
    padding: 10px;
    border-radius: 9px;
  }
}

.paragraph-heading {
  text-align: center;
  background-color: #dfdcdc;
  padding: 5px;
  font-family: sans-serif;
  color: #000;
  font-size: 17px;
  font-weight: 400;
}

.external-user-div {
  display: contents;
}

.external-user-div pre {
  font-family: revert;
  font-size: inherit;
  font-weight: 100;
}

.copy-clipboard {
  text-align: right;
  margin-top: 15px;
}

.copy-clipboard button {
  background-color: #2d3393;
  padding: 8px;
  border: 1px solid #a89d9d;
  border-radius: 9px;
  cursor: pointer;
  color: #fff;
}

.copy-clipboard button:hover {
  background-color: #060c69;
  padding: 8px;
  border-radius: 9px;
  cursor: pointer;
}
.copy-clipboard button:active {
  background-color: #2d3393;
  padding: 8px;
}
